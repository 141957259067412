import React from 'react'

import BrewedCoffee from '../../icons/brewed-coffee.svg'
import Cake from '../../icons/cake.svg'
import CancelCall from '../../icons/cancel-call.svg'
import Car from '../../icons/car.svg'
import Chats from '../../icons/chats.svg'
import ChineseTakeout from '../../icons/chinese-takeout.svg'
import Dinner from '../../icons/dinner.svg'
import FrenchFries from '../../icons/french-fries.svg'
import Gastronomy from '../../icons/gastronomy.svg'
import GlazedDonut from '../../icons/glazed-donut.svg'
import Hamburger from '../../icons/hamburger.svg'
import IceCreamBowl from '../../icons/ice-cream-bowl.svg'
import IceCream from '../../icons/ice-cream.svg'
import MapMarker from '../../icons/map-marker.svg'
import MessageApp from '../../icons/message-app.svg'
import Milkshake from '../../icons/milkshake.svg'
import PizzaSlice from '../../icons/pizza-slice.svg'
import Pizza from '../../icons/pizza.svg'
import Restaurant from '../../icons/restaurant.svg'
import Seafood from '../../icons/seafood.svg'
import ShoppingBasket from '../../icons/shopping-basket.svg'
import Social from '../../icons/social.svg'
import Taco from '../../icons/taco.svg'
import VibratePhone from '../../icons/vibrate-phone.svg'

import './style.scss'

const IconWeb = () => (
  <div className="iconWeb">
    <BrewedCoffee />
    <Cake />
    <CancelCall />
    <Car />
    <Chats />
    <ChineseTakeout />
    <Dinner />
    <FrenchFries />
    <Gastronomy />
    <Pizza />
    <GlazedDonut />
    <Hamburger />
    <IceCream />
    <MapMarker />
    <MessageApp />
    <IceCreamBowl />
    <Milkshake />
    <PizzaSlice />
    <Restaurant />
    <Seafood />
    <ShoppingBasket />
    <Social />
    <Taco />
    <VibratePhone />
  </div>
)

export default IconWeb
