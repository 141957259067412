import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SubscriptionForm from '../components/SubscriptionForm'
import IconWeb from '../components/IconWeb'

import './home.scss'

const IndexPage = () => (
  <Layout className="home">
    <SEO title="Home" keywords={[`bandwagon`, `takeout`, `delivery`]} />
    <div className="container">
      <article className="content">
        <h1>Food delivery without the wait</h1>
        <p>
          Bandwagon knows when people near you are getting food delivery, so you
          can jump on board to get food faster
        </p>
        <p>
          Sign up to join the waitlist and to get updates as we bring Bandwagon
          to your area!
        </p>
        <SubscriptionForm />
      </article>
      <article className="icons">
        <IconWeb />
      </article>
    </div>
  </Layout>
)

export default IndexPage
