import React from 'react'

import './style.scss'

export default class SubscriptionForm extends React.Component {
  state = { email: '', zip: '' }

  render() {
    const { state } = this

    return (
      <form
        className="subscriptionForm"
        action="https://gmail.us20.list-manage.com/subscribe/post"
        method="POST"
      >
        <input type="hidden" name="u" value="a69cf71b528ebdbec039bf9ea" />
        <input type="hidden" name="id" value="70a78098b7" />

        <div className="field-group">
          <label for="MERGE0">
            Email Address <span className="req asterisk">*</span>
          </label>
          <input
            type="email"
            autocapitalize="off"
            autocorrect="off"
            name="MERGE0"
            id="MERGE0"
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
          />
        </div>

        <div className="field-group">
          <label for="MERGE1">
            Zip Code <span className="req asterisk">*</span>
          </label>
          <input
            type="text"
            name="MERGE1"
            id="MERGE1"
            value={this.state.zip}
            onChange={e => this.setState({ zip: e.target.value })}
          />
        </div>

        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
        <div style={{ position: 'absolute', left: -5000 }} ariaHidden="true">
          <input
            name="b_eb05e4f830c2a04be30171b01_8281a64779"
            tabindex="-1"
            type="text"
            value=""
          />
        </div>

        <input
          className="button"
          id="mc-embedded-subscribe"
          name="subscribe"
          type="submit"
          value="Subscribe"
          disabled={!state.zip.length || !state.email.length}
        />
      </form>
    )
  }
}
